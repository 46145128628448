import axios from 'axios'
import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router'
import { URLS } from '../../services/urls'


export default function DisconnectInactiveUser({children}) {

  const [count, setCount] = useState(0)
  const [remaining, setRemaining] = useState(0)

  const navigate = useNavigate();
  const onIdle = () => {
    // Logout user
    if(!window.location.pathname.includes("/payments/deposit")){
      axios
      .get(URLS.BASE_URL + URLS.AUTHS.LOGOUT, {
        headers: { "Client-Id": URLS.CLIENT_ID },
      })
      .then((reps) => {
        window.localStorage.removeItem('user-details');
        window.localStorage.setItem('disconnected', 'true');
        window.localStorage.setItem('redirect', window.location.pathname);
        navigate("/login");
      });
    }
  }

  const onActive = () => {
  }

  const onAction = () => {
    setCount(count + 1)
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 999_000,
    // timeout: 180_000,
    throttle: 500,
    events: [
      'mousemove', 
      'keydown', 
      'wheel', 
      'DOMMouseScroll', 
      'mousewheel', 
      'mousedown', 
      'touchstart', 
      'touchmove', 
      'MSPointerDown', 
      'MSPointerMove', 
    ]
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [])

    return children
}