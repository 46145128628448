import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import ExportExcel from '../../components/download/DownloadExcel';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmDeletion } from '../../components/dletion';
import { useTranslation } from 'react-i18next';
import NewProductForm from './components/NewProduct';
import ManageStockForm from './components/ManageStockForm';
import PageTitle from '../../components/PageTitle';
import { formatNumber } from '../../components/utils/functions';

const exportColumns = [
  {
    label: 'Nom du produit',
    value: 'title',
  },
  {
    label: "Prix d'achat",
    value: 'buy_price',
  },
  {
    label: 'Prix de vente',
    value: 'price',
  },
  {
    label: 'Categorie',
    value: 'category',
  },
  {
    label: 'Marge',
    value: 'marge',
  },
  {
    label: 'Quantité',
    value: 'quantity',
  },
  {
    label: "Date d'ajout",
    value: 'created_at',
  },
  //   {
  //     label: 'Solde en compte',
  //     value: 'sold',
  //   },
  {
    label: 'Cree par',
    value: 'created_by',
  },
];

const exportServices = [
  {
    label: 'Nom du service',
    value: 'title',
  },
  {
    label: 'Prix',
    value: 'price',
  },
  {
    label: 'Categorie',
    value: 'category',
  },
  {
    label: "Date d'ajout",
    value: 'created_at',
  },
  //   {
  //     label: 'Solde en compte',
  //     value: 'sold',
  //   },
  {
    label: 'Cree par',
    value: 'created_by',
  },
];

const paginationComponentOptions = {
  // noRowsPerPage: true,
};

const ProductsLists = ({ type }) => {
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [toEdit, setToEdit] = useState();
  const [toEditStock, setToEditStock] = useState();
  const [page, setPage] = useState(1);

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: products,
    isLoading,
    mutate,
  } = useSWR(
    URLS.PRODUCTS.list(active_agency?.id, search, page, type === 'SERVICES' ? 'SERVICE' : 'PRODUCT')
  ); 
  const {
    data: categories,
    isLoading: loadingCategories,
    mutate: mutateCategories,
  } = useSWR(
    URLS.PRODUCTS.CATEGORIES.list(active_agency?.id, search, page, type === 'SERVICES' ? 'SERVICE' : 'PRODUCT')
  ); 

  const { t } = useTranslation();

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    setSelected(selectedRows);
  };

  const handlePageChange = ({ page }) => {
    if (page) {
      console.log(page);
      setPage(page);
    }
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const handleSuccess = () => {
    setToEdit();
    mutate();
  };

  const handleEdit = (obj) => {
    setToEdit(obj);
  };

  const handleDelete = (obj) => {
    confirmDeletion(URLS.PRODUCTS.delete(obj.id), t, mutate);
  };

  let columns = [
    {
      name: 'Nom du produit',
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Prix d'achat",
      selector: (row) => `${row.buy_price ?? '0'}`,
      sortable: true,
    },
    {
      name: 'Prix de vente',
      selector: (row) => `${formatNumber(row.price)}`,
      sortable: true,
    },
    {
      name: 'Marge',
      selector: (row) => `${row.price - row.buy_price}`,
      sortable: true,
    },
    {
      name: 'Quantité',
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Date d'ajout",
      selector: (row) => moment(row.created_at).format('lll'),
      sortable: true,
      val: 'created_at',
    },
    {
      name: 'Actions',
      selector: (row) => (
        <div className="d-flex gap-2">
          {row?.type === "PRODUCT" && <Link
            to="#"
            onClick={() => setToEditStock(row)}
            title="Réapprovisionner"
            data-bs-toggle="modal"
            data-bs-target=".new-stock-modal"
            className="btn btn-primary btn-sm btn-colapsed"
          >
            <i className="ri-download-2-line"></i>
            <span>Réapprovisionner</span>
          </Link>}
          <Link
            to="#"
            onClick={() => handleEdit(row)}
            title="Modifier"
            data-bs-toggle="modal"
            data-bs-target=".new-product-modal"
            className="btn btn-success btn-sm btn-colapsed"
          >
            <i className="mdi mdi-pencil"></i>
            <span>Modifier</span>
          </Link>
          <Link
            to={`/products/${row.id}`}
            title="Afficher"
            className="btn btn-warning btn-sm btn-colapsed"
          >
            <i className="mdi mdi-eye"></i>
            <span>Afficher</span>
          </Link>
          <Link
            to="#"
            title="Supprimer"
            onClick={() => handleDelete(row)}
            className="btn btn-danger btn-sm btn-colapsed"
          >
            <i className="mdi mdi-delete"></i>
            <span>Supprimer</span>
          </Link>
        </div>
      ),
    },
  ];
  if (type === 'SERVICES') {
    columns = [
      {
        name: 'Nom du service',
        selector: (row) => row.title,
        sortable: true,
      },
      {
        name: 'Prix',
        selector: (row) => `${row.price}`,
        sortable: true,
      },
      {
        name: "Date d'ajout",
        selector: (row) => moment(row.created_at).format('lll'),
        sortable: true,
        val: 'created_at',
      },
      {
        name: 'Actions',
        selector: (row) => (
          <div className="d-flex gap-2">
            <Link
              to="#"
              onClick={() => handleEdit(row)}
              title="Modifier"
              data-bs-toggle="modal"
              data-bs-target=".new-product-modal"
              className="btn btn-success btn-sm btn-colapsed"
            >
              <i className="mdi mdi-pencil"></i>
              <span>Modifier</span>
            </Link>
            <Link
              to={`/products/${row.id}`}
              title="Afficher"
              className="btn btn-warning btn-sm btn-colapsed"
            >
              <i className="mdi mdi-eye"></i>
              <span>Afficher</span>
            </Link>
            <Link
              to="#"
              title="Supprimer"
              onClick={() => handleDelete(row)}
              className="btn btn-danger btn-sm btn-colapsed"
            >
              <i className="mdi mdi-delete"></i>
              <span>Supprimer</span>
            </Link>
          </div>
        ),
      },
    ];
  }

  return (
    <div className="row">
      <div className="col-md-6">
        <PageTitle
          title={`${type === 'SERVICES' ? 'services' : 'produits'} `}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Liste des {type === 'SERVICES' ? 'services' : 'produits'}
              </li>
            </ol>
          }
        />
      </div>
      <div className="col-md-6">
        <div className="d-flex h-100">
          <div className="ms-auto my-auto">
            <div className="me-auto">
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light px-3 rounded"
                data-bs-toggle="modal"
                data-bs-target=".new-product-modal"
              >
                {' '}
                + Ajouter un {type === 'SERVICES' ? 'service' : 'produit'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-7">
                <div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="c-table-card-title">
                        {' '}
                        Liste des {type === 'SERVICES' ? 'services' : 'produits'}{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div>
                  <div className="">
                    <div className="row gy-2 gx-3 align-items-center">
                      <div className="col">
                        <div>
                          {/* <label htmlFor="autoSizingInput">Rechercher</label> */}
                          <div className="form search-form">
                            <i className="fa fa-search"></i>
                            <input
                              onChange={(e) => setSearch(e.target.value)}
                              type="text"
                              class="form-control form-input py-2"
                              placeholder="Rechercher"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div>
                          <div className="d-flex justify-content-end">
                           <ExportExcel
                              label={'Exporter'}
                              sheetName={`Customers page: ${page}`}
                              columns={type === 'SERVICES' ? exportServices : exportColumns}
                              dataToExport={(products?.results ?? []).map((customer) => {
                                return {
                                  ...customer,
                                  marge: `${customer.price - customer.buy_price}`,
                                  created_by: `${customer?.created_by?.first_name} ${customer?.created_by?.last_name}`,
                                };
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div>
                <div
                  className="modal fade new-product-modal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeModalLabel">
                          {toEdit
                            ? 'Modifitation du ' + type === 'SERVICES'
                              ? 'service'
                              : 'produit' + (toEdit.title ?? '')
                            : 'Nouveau ' + (type === 'SERVICES' ? 'service' : 'produit')}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <NewProductForm
                          type={type === 'SERVICES' ? 'SERVICE' : 'PRODUCT'}
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          toEdit={toEdit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <p className="card-title-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem aspernatur culpa animi. Veritatis doloremque earum, maiores, laboriosam deserunt voluptas magni provident ratione rerum a quasi quam sunt quisquam illo nam!
              </p> */}

            {/* Manage stock */}

            <div
              className="modal fade new-stock-modal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="myLargeModalLabel">
                      {'Réapprovisionnement pour << ' + toEditStock?.title + ' >>'}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <ManageStockForm
                      handleClose={handleClose}
                      onSuccess={handleSuccess}
                      toEdit={toEditStock}
                      side="IN"
                    />
                  </div>
                </div>
              </div>
            </div>
              <hr className="mb-0" />
            <div>
              
              <div>
                {
                  categories?.results?.map((category) => (
                    <div key={category.id} className='mb-4'>
                      <p class="h4 text-uppercase">{category.name}</p>
                      
                          <div className="table-responsive">
                            <table className="table align-middle table-nowrap mb-0">
                              <thead className="table-light">
                                <tr>
                                  <th className="text-uppercase">Code</th>
                                  <th className="text-uppercase">Nom</th>
                                  {type !== 'SERVICES' ? (
                                    <>
                                      <th className="text-uppercas">Prix d'achat</th>
                                      <th className="text-uppercas">Prix de vente</th>
                                      <th className="text-uppercas">Marge</th>
                                      <th className="text-uppercas">Quantité</th>
                                    </>
                                  ) : (
                                    <>
                                      <th className="text-uppercas">Prix</th>
                                    </>
                                  )}
                                  <th className="text-uppercas">Date d'ajout</th>
                                  <th className="text-uppercas">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(category?.services ?? [])?.map((item) => (
                                  <tr key={'itm-' + item.id}>
                                    <td>{item.reference}</td>
                                    <td>{item.title}</td>
                                    {type !== 'SERVICES' ? (
                                      <>
                                        <td>{item.buy_price} FCFA</td>
                                        <td>{item.price} FCFA</td>
                                        <td>{item.price - item.buy_price} FCFA</td>
                                        <td>{item.quantity}</td>
                                      </>
                                    ) : (
                                      <>
                                        <td>{item.price} FCFA</td>
                                      </>
                                    )}
                                    <td>
                                      {moment(item.created_at).format('DD/MM/YYYY')}{' '}
                                      <span className="ms-3">{moment(item.created_at).format('HH:MM')}</span>{' '}
                                    </td>
                                    <td>
                                      <div className="d-flex gap-2 justify-content-center">
                                        <Link
                                          to="#"
                                          onClick={() => setToEditStock(item)}
                                          title="Réapprovisionner"
                                          data-bs-toggle="modal"
                                          data-bs-target=".new-stock-modal"
                                          className="btn btn-primary btn-sm btn-colapsed"
                                        >
                                          <i className="ri-download-2-line"></i>
                                          <span>Réapprovisionner</span>
                                        </Link>
                                        <Link
                                          to="#"
                                          onClick={() => handleEdit(item)}
                                          title="Modifier"
                                          data-bs-toggle="modal"
                                          data-bs-target=".new-product-modal"
                                          className="btn btn-success btn-sm btn-colapsed"
                                        >
                                          <i className="mdi mdi-pencil"></i>
                                          <span>Modifier</span>
                                        </Link>
                                        <Link
                                          to={`/products/${item.id}`}
                                          title="Afficher"
                                          className="btn btn-warning btn-sm btn-colapsed"
                                        >
                                          <i className="mdi mdi-eye"></i>
                                          <span>Afficher</span>
                                        </Link>
                                        <Link
                                          to="#"
                                          title="Supprimer"
                                          onClick={() => handleDelete(item)}
                                          className="btn btn-danger btn-sm btn-colapsed"
                                        >
                                          <i className="mdi mdi-delete"></i>
                                          <span>Supprimer</span>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                    </div>
                  ))
                }
              </div>

              {/* <DataTable
                  // ref={tableRef}
                  columns={columns}
                  data={products?.results ?? []}
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleChange}
                  progressPending={isLoading}
                  paginationServer
                  paginationTotalRows={products?.count ?? 0}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[20]}
                  paginationComponentOptions={paginationComponentOptions}
                /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsLists;
